<template>
<v-container>
  <v-col cols="12"> </v-col>
    <v-row class="text-center" >
      <v-col cols="12" class="d-none d-xl-flex" xl="12" style="padding:2px"></v-col>
        <v-col v-for= "(item,i) in photos" :key="i"  
          :cols="(item.format  == 'vertical'? 1: 2 )* 3" 
          :lg="(item.format  == 'vertical'? 1: 2 ) * 2" 
          :md="(item.format  == 'vertical'? 1: 2 ) * 2" 
          :xl="(item.format  == 'vertical'? 1: 2 )* 2" 
          style="padding:2px">
          <v-img contain :class="[item.format, 'd-flex']"  :src="item.main_url" @click=" overlay = !overlay; overlayPic=i; " ></v-img>
        </v-col>
      <v-col cols="0" class="d-none d-xl-flex" xl="12" style="padding:2px"></v-col>
    </v-row>
      <v-overlay :value="overlay"  opacity='.85'  @keypress.39="onClickNext();">
        <v-row
            :class="['fill-width fill-height ma-0']"
            align="center"
            justify="center"
            v-click-outside="onClickOutside"
          >
          <v-col :cols="photos[overlayPic].format  == 'vertical'? 8: 6 "  md="12" lg="12" xl="12" >  
            <v-img contain :class="['d-flex']"  :src="photos[overlayPic].main_url" >
              <div class="NextDivision" @click="onClickNext();" > 
                <v-icon large color="rgba(150, 150, 150, 0.4)" class="NextButton" >mdi-arrow-right-circle </v-icon>
              </div> 
              <div class="BackDivision" @click="onClickBack(); "> 
                <v-icon large color="rgba(150, 150, 150, 0.4)" class="BackButton" >mdi-arrow-left-circle </v-icon>
              </div>
              <div class="Description">
                <div class="tittle">
                 <v-icon color="rgba(255, 255, 255, 0.7)" >mdi-image </v-icon> {{photos[overlayPic].name }} 
                </div>
                <div class="location" >  
                   <v-icon color="rgba(255, 255, 255, 0.7)" >mdi-map-marker </v-icon> {{photos[overlayPic].Location }}      
                </div>
              </div> 
            </v-img>
          </v-col>
        </v-row>
   </v-overlay>
  </v-container>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'Photography',
      mounted () {
    axios
      .post('https://5zk1teqy2j.execute-api.us-east-1.amazonaws.com/default/getPhotos', 
        {'format':'' },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'uKsWIugxjV9T5BptEK79P9NClh2Gxw4B1ywkhoJf'}
        }
      ).then(response => (this.photos = response.data))
    },
    data: () => ({
      photos: [
        { name: 'Light & Shadow', main_url: 'https://s3.amazonaws.com/isaacarenasphotography/portrait/ContraLuzH.jpg', format: 'vertical', cols:1  }
      ],
      overlay: false,
      overlayPic: 1,
      zIndex: 1,
        }),
    methods: {
      onClickOutside () {
        this.overlay = false
      },
      onClickNext () {
        if (this.overlayPic < this.photos.length -1){
          this.overlayPic++
        }else{
           this.overlayPic=0
        }
      },
      onClickBack () {
        if (this.overlayPic ==0  ){
          this.overlayPic= this.photos.length -1
        }else{
           this.overlayPic--
        }
      },
      
    },
    }
</script>

<style>
.vertical {
  max-width: 87%;
  height: auto;
  margin-left: 4%;
}
.horizontal {
  max-width: 98%;
  height: auto;
  margin-left: 0;
}
.pictureFrame_vertical {
  max-width: 70%;
  vertical-align: center;
  text-align: center;
}
.pictureFrame_horizontal {
  max-width: 45%;
  vertical-align: center;
  text-align: center;
}
.NextDivision{
  position: absolute;
  top: 0%;
  width: 50%;
  height: 80%;
  left: 50%;
  font-size: 100%;
  background: rgba(0, 0, 0, .0 ) ;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(200, 200, 200, .0), rgba(2, 20, 20, .01));
}
.NextButton {
  position: absolute;
  left: 80%;
  top: 50%;
  font-size: 800%;
 
}
.BackDivision{
  position: absolute;
  top: 0%;
  width: 50%;
  height: 80%;
  left: 0%;
  font-size: 100%;
  background: rgba(0, 0, 0, .0 ) ;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(200, 200, 200, .0), rgba(2, 20, 20, .01));
}
.BackButton {
  position: absolute;
  left: 10%;
  top: 50%;
  font-size: 800%;
}
.Description{
  position: absolute;
  top: 80%;
  width: 100%;
  height: 20%;
  left: 0%;
  font-size: 100%;
  background: rgba(0, 0, 0, .1 ) ;
  background-image: linear-gradient(to right, rgba(0, 0, 0, .3), rgba(100, 100, 100, .2), rgba(255, 255, 255, .0));
}
.tittle{
  color: rgba(255, 255, 255, .8);
  padding-left: 2%;
  font-family: 'Anton', sans-serif;
  font-size: 80%;
}
.location{
  color: rgba(255, 255, 255, .8);
  padding-left: 2%;
  font-family: 'Anton', sans-serif;
  font-size: 80%;
}

</style>