<template>
<v-container>
  <v-col cols="12"> </v-col>
    <v-row v-for= "(item,i) in menuPhotos" :key="i" class="text-center" >
      <v-col cols="0" class="d-none d-xl-flex" xl="2" style="padding:2px"></v-col>
       <v-col cols="12" lg="12" xl=8 style="padding:2px">
          <v-img contain width="100%" class="d-none d-lg-flex" :src="item.photo">
            <div :class="item.labelPosition"> {{ item.section }} </div>
            <div class="bottomRightAbout"> {{ item.about }} </div>
            
          </v-img>
          <v-img contain class="d-flex d-lg-none"  :src="item.photo" >
            <div :class="item.labelPositionSm"> {{ item.section }} </div>
            
          </v-img>
      </v-col>
      <v-col cols="0" class="d-none d-xl-flex" xl="2" style="padding:2px"></v-col>
    </v-row>
    
    
  </v-container>
</template>

<script>
  export default {
    name: 'Resume',
    data: () => ({
      menuPhotos : [
        {
          section:"Isaac Arenas Franco",
          about: "Isaac is a Software Engineer that has being working since 2008 has work in almost all the positions of the Software life cycle and is versatile in a lot of coding languages and technologies",
          photo:"https://isaacarenasphotography.s3.amazonaws.com/portrait/resumePage.jpg",
          labelPosition:"bottomRightResume",
          labelPositionSm:"bottomRightResumeSM"
        },   
      ]
      }),
  }
</script>

<style>
/* Bottom right text */

.bottomRightResume {
    position: absolute;
    bottom: 30%;
    right: 0%;
    color: #FFFFFF;
    font-family: 'Anton', sans-serif;
    font-size: 200%;
    background: rgba(3, 3, 3, .8);
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5px ;
    padding-bottom: 5px;
}
.bottomRightResumeSM{
    position: absolute;
    bottom: 10%;
  
    right: 0%;
    color: #FFFFFF;
    font-family: 'Anton', sans-serif;
    font-size: 90%;
    background: rgba(3, 3, 3, .8);
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5px ;
    padding-bottom: 5px;
}
.bottomRightAbout {
    position: absolute;
    bottom: 15%;
    width: 42%;
    right: 0%;
    color: #FFFFFF;
    font-family: 'Fjalla+One', sans-serif;
    font-size: 95%;
    background: rgba(3, 3, 3, .8);
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 5px ;
    padding-bottom: 5px;
}
.bottomRightAboutSM{
    position: absolute;
    bottom: 7%;
    width: 50%;
    right: 0%;
    color: #FFFFFF;
    font-family: 'Fjalla+One', sans-serif;
    font-size: 90%;
    background: rgba(3, 3, 3, .8);
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 5px ;
    padding-bottom: 5px;
}

</style>