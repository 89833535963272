import Vue from 'vue';
import Router from 'vue-router';
import Index from '@/components/Index';
import Resume from '@/components/Resume';
import Photography from '@/components/Photography';

Vue.use(Router);

export default new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index,
    },
    {
      path: '/Photography',
      name: 'Photography',
      component: Photography,
    },
    {
      path: '/Resume',
      name: 'Resume',
      component: Resume,
    },
  ],
});
