<template>
  <v-app >
     <v-app-bar
      fixed
      color="#333333"
      dark
      scroll-target="#scrolling-techniques-2"
    >
      <router-link  to="/" class="d-flex align-center">
        <v-img
          alt="Logo"
          class="d-flex d-col-2 shrink mr-2"
          contain
          src="./assets/mainLogo.png"
          transition="scale-transition"
          max-width="90"
         
        />
      </router-link>
      <!-- Main Menu Section Would Only show on bigger than md  -->
      <v-spacer class="d-none d-md-flex"></v-spacer>
      <div class="d-none d-md-flex " align="center">
        <v-btn :to="section.section" v-for='(section,i) in sections' :key='i' plain>
          <v-icon >{{section.icon}} </v-icon>
          {{section.section}}
        </v-btn>
      </div>
       
      <!-- social Network Section -->
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <center>
        <v-btn  v-for='(social,i) in socials' :key='i'  :href='social.route'  icon target="_blank" rel="noopener noreferrer"> 
          <v-icon >{{ social.icon }} </v-icon>
        </v-btn>
        </center> 
      </div>
    </v-app-bar>
   
    <!-- main app handled by the router -->
    <v-main id="scrolling-techniques-2" class="overflow-y-auto" style="margin-top=45px; padding-bottom=0px"  >
       <router-view></router-view>
    </v-main>

     <!-- bottom navigation --> 
    <v-bottom-navigation app fixed dark class="d-flex d-md-none">
      <v-btn :to="section.section" v-for='(section,i) in sections' :key='i' plain>
        <span>{{section.section}}</span>
        <v-icon>{{section.icon}}</v-icon>
      </v-btn>
  </v-bottom-navigation>
  </v-app>
</template>

<script>
import axios from 'axios';
export default {
  
  name: 'App',
  
   mounted () {
    axios
      .post('https://rg24xdvhj6.execute-api.us-east-1.amazonaws.com/default/iarenas-getSections', 
        {'type':'social' },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': '2mSfVtD5Up13GxNnrcA5z4Nf66FHhczGgBpkZxj9'}
        }
      ).then(response => (this.socials = response.data))
    axios
      .post('https://rg24xdvhj6.execute-api.us-east-1.amazonaws.com/default/iarenas-getSections', 
        {'type':'section' },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': '2mSfVtD5Up13GxNnrcA5z4Nf66FHhczGgBpkZxj9'}
        }
      ).then(response => (this.sections = response.data))
    },
 

  data: () => ({
    value:1,
    socials:"",
    sections:""
  }),
};
</script>

<style>
#app {
margin-top: 30px;
padding-bottom: 0px;
}

</style>

