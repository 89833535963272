<template>
<v-container>
  <v-col cols="12"> </v-col>
    <v-row v-for= "(item,i) in info" :key="i" class="text-center" >
      <v-col cols="0" class="d-none d-xl-flex" xl="2" style="padding:2px"></v-col>
       <v-col cols="12" lg="12" xl=8 style="padding:2px">
        <router-link :to="item.section" >
          <v-img contain width="100%" class="d-none d-lg-flex" :src="item.photo">
            <div :class="item.labelPosition"> {{ item.section }} </div>
          </v-img>
          <v-img contain class="d-flex d-lg-none"  :src="item.photo" >
            <div :class="item.labelPositionSm"> {{ item.section }} </div>
          </v-img>
        </router-link>
      </v-col>
      <v-col cols="0" class="d-none d-xl-flex" xl="2" style="padding:2px"></v-col>
    </v-row>
    
    
  </v-container>
</template>

<script>
import axios from 'axios';

  export default {
    name: 'Index',
    mounted () {
    axios
      .post('https://rg24xdvhj6.execute-api.us-east-1.amazonaws.com/default/iarenas-getSections', 
        {
            'type':'section'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': '2mSfVtD5Up13GxNnrcA5z4Nf66FHhczGgBpkZxj9'}
        }
      )      
      .then(response => (this.info = response.data))
    },
    data: () => ({
      info:""
      }),
  }
</script>

<style>
/* Bottom right text */
.bannerRight {
  position: absolute;
  bottom: 0%;
  right: 0%;
  color: rgba(255, 255, 255, .9);
  font-family: 'Pathway Gothic One', sans-serif;
  font-size: 700%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(200, 200, 200, .0), rgba(3, 3, 3, .4));
  padding-left: 100%;
  padding-right: 2%;
  padding-top: 100% ;
  padding-bottom: 5px;
}
.bottomRight {
    position: absolute;
    bottom: 10%;
    right: 0%;
    color: #FFFFFF;
    font-family: 'Anton', sans-serif;
    font-size: 200%;
    background: rgba(3, 3, 3, .8);
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5px ;
    padding-bottom: 5px;
}

.bottomRight:hover {
  color: #333333;
  background: rgba(255, 255, 255, .8);
}
.bannerLeft {
  position: absolute;
  bottom: 0%;
  left: 0%;
  color: rgba(255, 255, 255, .8);
  font-family: 'Pathway Gothic One', sans-serif;
  font-size: 700%;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(200, 200, 200, .0), rgba(3, 3, 3, .4));
  padding-left: 2%;
  padding-right: 100%;
  padding-top: 100% ;
  padding-bottom: 5px;
}
.bannerLeft:hover {
  color: rgba(255, 255, 255, .9);
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(3, 3, 3, .1), rgba(3, 3, 3, .8)); 
}
/* Bottom left text */
.bottomRightSM {
    position: absolute;
    bottom: 10%;
    right: 0%;
    color: #FFFFFF;
    font-family: 'Anton', sans-serif;
    font-size: 100%;
    background: rgba(3, 3, 3, .8);
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5px ;
    padding-bottom: 5px;
}
/* Bottom right text */
.bottomLeft {
    position: absolute;
    bottom: 10%;
    left: 0%;
    color: #FFFFFF;
    font-family: 'Anton', sans-serif;
    font-size: 200%;
    background: rgba(3, 3, 3, .8);
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5px ;
    padding-bottom: 5px;
}
.bottomLeftSM {
    position: absolute;
    bottom: 10%;
    left: 0%;
    color: #FFFFFF;
    font-family: 'Anton', sans-serif;
    font-size: 100%;
    background: rgba(3, 3, 3, .8);
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5px ;
    padding-bottom: 5px;
}

</style>